import { Flex, FlexProps } from "@chakra-ui/react"
import React from "react"

export interface ContainerProps extends React.PropsWithChildren, FlexProps { backgroundColor?: string }
export const Container = ({ children, backgroundColor, ...props }: ContainerProps): JSX.Element => {
  return (
    <Flex flexDir="column" maxWidth="1200" paddingX="32" margin="0 auto" backgroundColor={backgroundColor} {...props}>
      {children}
    </Flex>
  )
}
