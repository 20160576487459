import { Box, Center, Flex, Heading, useSafeLayoutEffect, Text, HStack, Link } from "@chakra-ui/react"
import React, {PropsWithChildren, useState } from "react"
import { Navigate, NavLink, Outlet, RouteObject, useLoaderData } from "react-router-dom"
import remarkGfm from "remark-gfm"
import ReactMarkdown from "react-markdown"

import ethics from "./_components/ethics.md"
import privacy from "./_components/privacy.md"
import terms from "./_components/terms.md"
import { Container } from "../../components/Container"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { isExternalURL } from "../../utils"

const docs: Record<string, string> = {
  terms, ethics, privacy
}

const LinkRenderer: React.FC<any> = ({href, ...props}) => {
  const isExternal = isExternalURL(href || "")

  return isExternal ?(
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={href} target="_blank" rel="noopener noreferrer" {...props}/>
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  ) : <a href={href} {...props}/>
}

const Document = () => {
  const loaderData = useLoaderData()


  const [content, setContent] = useState("Loading...")

  useSafeLayoutEffect(() => {
    if (loaderData) {
      fetch(loaderData as string)
        .then((response) => response.text())
        .then((content) => setContent(content))
    }
  }, [loaderData])

  if (!Boolean(loaderData)) {
    return <Box><Center><Heading>404</Heading></Center></Box>
  }

  return (
    <Box>
      <Container maxWidth="768" paddingTop="24" paddingBottom="72">
        <Box className="md">
          <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} components={{ a: LinkRenderer }} />
        </Box>
      </Container>
    </Box>
  )
}

const getDocData = (id?: string): string | null => {
  if(id) {
    return (Object.keys(docs).includes(id) ? docs[id] as string : null)
  }
  return null
}

const DocumentHeader: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box w="full" bg="sky">
      <Box w="full" borderBottom="1px solid black" backgroundColor="midnight" pt="120">
        <Container maxWidth="768">
          <Flex>
            <Link variant="buttonAlt" size="buttonMd" color="black" href="/" _hover={{ textDecoration: "none", backgroundColor: "sky" }}><ArrowBackIcon /> Back to Home</Link>
          </Flex>
          <HStack spacing="0" marginTop="48">
            {Object.keys(docs).map((doc, key) => (
              <NavLink to={doc} key={key}>
                {({isActive}) => (
                  <Text
                    textTransform="capitalize"
                    fontWeight="600"
                    backgroundColor={isActive ? "lightcyan" : "unset"}
                    color={isActive ? "black": "white"}
                    paddingX="16"
                    paddingTop="16"
                  >
                    {doc}
                  </Text>
                )}
              </NavLink>
            ))}
          </HStack>
        </Container>
      </Box>
      {children}
    </Box>
  )
}

export const docsRoutes: RouteObject[] = [
  {
    element: <DocumentHeader><Outlet /></DocumentHeader>,
    children: [
      {
        index: true,
        element: <Navigate to="terms" />
      },
      {
        path: ":documentId",
        loader: ({ params }) => {
          return getDocData(params.documentId)
        },
        element: <Document />
      }
    ]
  },
]
