import { Heading, SimpleGrid, Text, VStack, WrapItem } from "@chakra-ui/react"
import React from "react"
import { Container } from "../../components/Container"
import { useContent } from "../../data/provider"

export const FAQ = (): JSX.Element => {
  const { faq } = useContent()
  return (
    <Container>
      <Heading marginBottom="32" fontSize={{md: "32", base:"24"}} fontWeight="700">Frequently Asked Questions</Heading>
      <SimpleGrid columns={{md: 2, base: 1}} spacingX="32" spacingY="24">
        {faq.map((f) => (
          <WrapItem maxW="560">
            <VStack spacing="8" align="left">
              <Text fontSize="16" fontWeight="700">{f.question}</Text>
              <Text fontSize="14" fontWeight="400">{f.answer}</Text>
            </VStack>
          </WrapItem>
        ))}
      </SimpleGrid>
    </Container>
  )
}
