import { chakra, Box } from "@chakra-ui/react"
import React from "react"
import { Outlet, RouteObject } from "react-router-dom"
import { docsRoutes } from "./docs";
import { About } from "./_components/About";
import { FAQ } from "./_components/FAQ";
import { Footer } from "./_components/Footer";
import { Landing } from "./_components/Landing";
import { Ocean } from "./_components/Ocean";
import { Schedule } from "./_components/Schedule";
import { HomePageTitleBar } from './_components/TitleBar';

const HomePage = (): JSX.Element => {
  return (
    <Box w="full" h="full" position="relative">
      <HomePageTitleBar />
      <chakra.section id="home" bg="sky" paddingBottom="48">
        <Landing />
      </chakra.section>
      <Box backgroundColor="lightcyan">
        <chakra.section>
          <Ocean />
        </chakra.section>
        <chakra.section id="about">
          <About />
        </chakra.section>
        <chakra.section id="schedule">
          <Schedule />
        </chakra.section>
        <chakra.section id="faq" paddingTop="120"><FAQ/></chakra.section>
        <chakra.section id="contact" paddingTop="160">
          <Footer />
        </chakra.section>
      </Box>
    </Box>
  )
}

export const appRoutes: RouteObject[]  = [
  {
    path: "/",
    element: (
      <Outlet />
    ),
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: "docs",
        children: docsRoutes,
      }
    ]
  }
]
