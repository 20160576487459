import React from "react"
import { Container } from "components/Container"
import { Heading, HStack, Text, VStack, Link, Stack } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"

const REGISTRATION_LINK = "https://form.jotform.com/232525142520040"
const SPONSOR_LINK = "mailto:silen.naihin@agpt.co,nicholas.tindle@agpt.co"
const HAPPY_HOUR_LINK = "https://partiful.com/e/0EepI5Fdi0qzDix1gF0s"
const DEVPOST_LINK = "https://b3hack.devpost.com"

export const Landing = () => {
  return (
    <Container>
      <VStack alignItems="start" spacing="18" marginTop="160">
        <Text textTransform="uppercase" fontWeight="500" fontSize={{md: "20", base: "14"}}>AutoGPT Presents</Text>
        <Heading fontSize={{md: "64", base: "32"}} fontWeight="700">Beat, Build, Break</Heading>
        <Text fontWeight="500" fontSize={{md: "32", base: "20"}}>Autonomous Agent Hackathon</Text>
      </VStack>
      <HStack marginTop="50" spacing="32">
        <Link variant="button" size={{md: "buttonLg", base: "buttonMd"}} href={DEVPOST_LINK} isExternal>Devpost <ExternalLinkIcon /></Link>
        {/* <Link variant="buttonAlt" size={{md: "buttonLg", base: "buttonMd"}} href={SPONSOR_LINK} isExternal>Sponsor <ExternalLinkIcon /></Link> */}
      </HStack>
      <Stack direction={{ md: "row", base: "column"}} justify="right" marginTop="56" spacing={{md: "48", base: "24"}} fontSize="16" alignItems="start">
        {[{title: "When", info: "October 7 & 8, 2023"}, { title: "Where", info: <> Union Square <br/> San Francisco, CA</>}].map(({title, info}, key) => {
          return (<VStack spacing="8" key={key} alignItems="start">
            <Text textTransform="uppercase" color="orange" fontWeight="600">{title}</Text>
            <Text fontWeight="500">{info}</Text>
          </VStack>)
        })}
      </Stack>
    </Container>
  )
}
