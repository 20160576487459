import { Box, Flex, HStack, Image, Show, useDisclosure } from "@chakra-ui/react"
import React from "react"
import titleBarImage from "assets/images/title-bar-image.png"
import { HamburgerIcon } from "@chakra-ui/icons"
import { SideMenu } from "./SideMenu"

export type HeaderNavLink = {
  title: string
  to: string
}

const navLinks: HeaderNavLink[] = [
  {
    title: "Home",
    to: "#home",
  },
  {
    title: "About",
    to: "#about",
  },
  {
    title: "FAQ",
    to: "#faq",
  },
  {
    title: "Contact",
    to: "#contact"
  }
]

export const HomePageTitleBar = () => {

  const methods = useDisclosure()

  const { onOpen } = methods

  return (
    <Box w="full" position="fixed" h="80" backgroundColor="white" paddingX={{md: "120", base: "24"}} zIndex="100">
      <Image src={titleBarImage} h="full" w="auto" position="absolute" paddingY={{md: "16", base: "10"}} alt="autogpt-logo"/>
      <Show above="lg">
        <Flex alignItems="center" justifyContent="space-around" h="full">
          <HStack spacing="60" color="black" textAlign="center" fontWeight="600" fontFamily="heading">
            {navLinks.map((navLink) => (<a href={navLink.to}>{navLink.title}</a>) )}
          </HStack>
        </Flex>
      </Show>
      <Show below="lg">
        <Flex justifyContent="flex-end" alignItems="center" h="full">
          <Box as="button" onClick={onOpen}>
            <HamburgerIcon boxSize="40" />
            <SideMenu methods={methods} navLinks={navLinks} />
          </Box>
        </Flex>
      </Show>
    </Box>
  )
}
