import { Box, Heading, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { Container } from "../../components/Container"

type AboutCardProps = {
  title: string
  description: string
  backgroundColor: string
}
const AboutCard = ({title, description, backgroundColor}: AboutCardProps) => {
  return (
    <Box borderRadius="16" borderTopRightRadius="72" backgroundColor={backgroundColor} paddingX="32" paddingBottom="32" paddingTop="56" w="340">
      <Heading fontSize="24" fontWeight="700" textTransform="uppercase">{title}</Heading>
      <Text fontSize="14" marginTop="16">{description}</Text>
    </Box>
  )
}

export const About = () => {
  return (
    <Container backgroundColor="lightcyan">
      <Heading fontSize={{md: "32", base: "24"}} fontWeight="700">About</Heading>
      <Text marginTop="32" fontWeight="600" fontSize={{md: "24", base: "20"}} color="#2F3E44">Join AutoGPT in San Francisco for the ultimate Autonomous Agent hackathon!</Text>
      <Text marginTop={{md: "16", base: "14"}}>Cultivate your AI skills, engage with industry leaders, and push the boundaries of artificial intelligence and agent benchmarks. Join us for a memorable weekend of lightning talks, social gatherings, and coveted prizes.</Text>
      <Stack spacing="32" direction={{xl: "row", base: "column"}} margin="0 auto" marginTop="48" justify="center">
        <AboutCard backgroundColor="lightblue" title="Beat" description="Create AI agents that beat established benchmarks, setting new standards for excellence and competing for prizes." />
        <AboutCard backgroundColor="lighterblue" title="Build" description="Embrace innovation by building your own benchmarks, propelling the field with creative challenges and expertise." />
        <AboutCard backgroundColor="sky" title="Break" description="Unleash creativity to build solutions that break benchmarks or advance the field in unprecedented ways, aiming for game-changing innovations."/>
      </Stack>
    </Container>
  )
}
