import { createContainer } from "unstated-next";
import { faqData } from "./faq";

export const ContentContainer = createContainer(() => (
  {
    faq: faqData
  }
))

export const useContent = ContentContainer.useContainer
