
export type FAQ = {
  question: string,
  answer: JSX.Element | string
}
export const faqData: FAQ[] = [
  {
    question: "Who can attend",
    answer: "All individuals 18+ are invited to participate. While we are interested in applicants with interest and experience in AI, we encourage everyone to apply, regardless of skill level."
  },
  {
    question: "Do I need to pay to attend?",
    answer: "This event is free to all participants. We will provide everything you need to build, from meals to snacks to swag and more!",
  },
  {
    question: "What should I bring?",
    answer: <>You'll need your laptop, chargers, and cell phone to start. If you plan on staying overnight, other useful items include toiletries, a change of clothes, a jacket, an eye mask, and earplugs. <br/> Weapons, e-cigarettes, drugs, and other illegal things are not allowed. </>
  },
  {
    question: "What can I work on?",
    answer: "The majority of the work you submit must be created at the hackathon. You can use open-source products in your submission, or continue an existing project, but the majority must be original work created by you and your team during the period of the hackathon, and what is presented must be work done at the hackathon."
  },
  {
    question: "Can I stay overnight at the venue?",
    answer: "The venue will stay open 24/7 over the hackathon weekend. You are welcome to stay overnight, however, we will not be providing a sleeping area.",
  },
  {
    question: "How can I meet other attendees?",
    answer: "There will be dedicated hackathon channels on the AutoGPT Discord server for you to connect with other participants and communicate during the hackathon. For in-person activities, we are hosting a team-building session and a Friday Happy Hour. More info soon!",
  },
  {
    question: "How do teams work?",
    answer: "Each participant must register and be accepted individually. You are welcome to attend on your own or find a team at the event. Teams are limited to max 5 members.",
  },
  {
    question: "When does the event start?",
    answer: "Doors will open at 8:30am on Saturday, October 7th and the event will conclude by 3pm on Sunday, October 8th."
  },
  {
    question: "What are the prizes?",
    answer: "We'll be offering prizes for Build, Beat, and Break. Prizes will be announced soon, but will be valued at >$1000."
  },
  {
    question: "How will we be judged?",
    answer: "Build and Beat will be judged by a panel of judges from AutoGPT and sponsors, and the Break prize will be audience-judged. All projects will be presented on stage and all projects will be eligible for any of the prizes."
  }
]
