import { Box, Heading } from "@chakra-ui/react"
import React from "react"

export const Schedule = () => {
  return (
    <Box maxWidth="1200" margin="0 auto" padding="16" marginTop="120">
      <Heading marginBottom="32">Schedule</Heading>
      <iframe title="schedule" src="https://teamup.com/ksqj43f2a2tzca3ut7?showLogo=0&showSearch=0&showProfileAndInfo=0&showSidepanel=1&disableSidepanel=1&showTitle=0&showViewSelector=1&showMenu=0&showAgendaHeader=1&showAgendaDetails=0&showYearViewHeader=1" style={{ width: "100%", height: "800px" }} loading="lazy" frameBorder="0"></iframe>
    </Box>
  )
}
