import { Box, Image } from "@chakra-ui/react"
import React from "react"
import gradient from "assets/gradient_gfx.svg"

export const Ocean = () => {
  return (
    <Box w="full" maxH="500" position="relative" zIndex="0">
      <Image zIndex="2" position="relative" h="500" src={gradient} w="full"/>
        {/* <Gradient style={{ objectFit: "contain", objectPosition: "center center"}} />
      </Box> */}
      <Box position="absolute" top="-1px" left="0" bg="sky" height="80" w="full" zIndex="0" />
    </Box>
  )
}
