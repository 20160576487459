import { Global } from "@emotion/react"
import React from "react"

export const Fonts = () => {
  return (
    <Global styles={`
        * {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        #root {
          height: 100vh;
          width: 100vw;
          overflow: auto;
          scroll-behavior: smooth;
          --scroll-behavior: smooth;
        }

        body {
          overflow: auto;
        }

        html {
          overflow: hidden;
        }

        strong {
            font-weight: 700
        }

        // Markdown Reset
        .md {
          font-family: "Hind"
        }

        /* Blocks
        =============================================================================*/

        .md p, .md blockquote, .md ul, .md ol, .md dl, .md table, .md pre {
          margin: 12px 0;
        }

        /* Headers
        =============================================================================*/

        .md h1, .md h2, .md h3, .md h4, .md h5, .md h6 {
          font-weight: bold;
          -webkit-font-smoothing: antialiased;
          font-family: "Montserrat"
        }

        .md h1 {
          font-size:2.5em;
          color:#000;
          margin-bottom: 48px;
        }

        .md h2 {
          font-size:2em;
          color:#111;
        }

        .md h3 {
          font-size:1.6em;
          color:#222;
          }

        .md h4 {
          font-size:1.2em;
          color:#333;
        }

        .md body>h2:first-child, .md body>h1:first-child, .md body>h1:first-child+h2, .md body>h3:first-child, .md body>h4:first-child, .md body>h5:first-child, .md body>h6:first-child {
          margin-top: 0;
          padding-top: 0;
        }

        .md a:first-child h1, .md a:first-child h2, .md a:first-child h3, .md a:first-child h4, .md a:first-child h5, .md a:first-child h6 {
          margin-top: 0;
          padding-top: 0;
        }

        .md h1+p, .md h2+p, .md h3+p, .md h4+p, .md h5+p, .md h6+p {
          margin-top: 10px;
        }

        /* Links
        =============================================================================*/

        .md a {
          color: #0000EE;
          text-decoration:none;
        }
        .md a:hover {
          color:#0a0277;
          text-decoration:underline;
        }

        /* Lists
        =============================================================================*/

        .md ul, .md ol {
          padding-left: 20px;
        }

        .md ul {
          padding-left: 32px;
        }

        .md ul li > :first-child,
        .md ol li > :first-child,
        .md ul li ul:first-of-type,
        .md ol li ol:first-of-type,
        .md ul li ol:first-of-type,
        .md ol li ul:first-of-type {
          margin-top: 0px;
        }

        .md ul ul, .md ul ol, .md ol ol, .md ol ul {
          margin-bottom: 0;
        }

        .md dl {
          padding: 0;
        }

        .md dl dt {
          font-size: 14px;
          font-weight: bold;
          font-style: italic;
          padding: 0;
          margin: 12px 0 5px;
        }

        .md dl dt:first-child {
          padding: 0;
        }

        .md dl dt>:first-child {
          margin-top: 0px;
        }

        .md dl dt>:last-child {
          margin-bottom: 0px;
        }

        .md dl dd {
          margin: 0 0 15px;
          padding: 0 15px;
        }

        .md dl dd>:first-child {
          margin-top: 0px;
        }

        .md dl dd>:last-child {
          margin-bottom: 0px;
        }

        /* Code
        =============================================================================*/

        .md pre, .md code, .md tt {
          font-size: 12px;
          font-family: Consolas, "Liberation Mono", Courier, monospace;
        }

        .md code, .md tt {
          margin: 0 0px;
          padding: 0px 0px;
          white-space: nowrap;
          border: 1px solid #eaeaea;
          background-color: #f8f8f8;
          border-radius: 3px;
        }

        .md pre>code {
          margin: 0;
          padding: 0;
          white-space: pre;
          border: none;
          background: transparent;
          word-wrap: break-word;
        }

        .md pre {
          background-color: #f8f8f8;
          font-size: 13px;
          line-height: 19px;
          overflow: auto;
          padding: 10px 15px;
          border-radius: 3px;
        }

        .md pre code, .md pre tt {
          background-color: transparent;
          border: none;
        }

        .md kbd {
            -moz-border-bottom-colors: none;
            -moz-border-left-colors: none;
            -moz-border-right-colors: none;
            -moz-border-top-colors: none;
            background-color: #DDDDDD;
            background-image: linear-gradient(#F1F1F1, #DDDDDD);
            background-repeat: repeat-x;
            border-color: #DDDDDD #CCCCCC #CCCCCC #DDDDDD;
            border-image: none;
            border-radius: 2px 2px 2px 2px;
            border-style: solid;
            border-width: 1px;
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            line-height: 10px;
            padding: 1px 4px;
        }

        /* Quotes
        =============================================================================*/

        .md blockquote {
          border-left: 4px solid #DDD;
          padding: 0 15px;
          color: #777;
        }

        .md blockquote>:first-child {
          margin-top: 0px;
        }

        .md blockquote>:last-child {
          margin-bottom: 0px;
        }

        /* Horizontal Rules
        =============================================================================*/

        .md hr {
          clear: both;
          margin: 15px 0;
          height: 0px;
          overflow: hidden;
          border: none;
          background: transparent;
          border-bottom: 2px solid #ddd;
          padding: 0;
        }

        /* Table
        =============================================================================*/

        .md table {
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          margin: 1em 0;
          width: 100%;
          max-width: 100%;
          border-width: 1px;
          border-style: solid;
          background-color: transparent;
        }

        .md table, .md table tr, .md table tr td, .md table tr th {
          border-color: #e5e5e5;
        }

        .md table th {
          color: #666666;
          background-color: #fdfdfd;
        }

        .md tr th, .md tr td {
          padding: 6px 13px;
        }

        .md table tbody > tr:nth-child(odd) > td,.md table tbody > tr:nth-child(odd) > th {
          background-color: #f9f9f9;
        }

        /* Images
        =============================================================================*/

        .md img {
          max-width: 100%
        }
    `}/>
  )
}
