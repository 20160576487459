import { CloseButton, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, UseDisclosureReturn, VStack } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { HeaderNavLink } from "./TitleBar"

export type SideMenuProps = {
  methods: UseDisclosureReturn
  navLinks: HeaderNavLink[]
}
export const SideMenu = ({methods, navLinks}: SideMenuProps): JSX.Element => {
  const {isOpen, onClose: onDiscClose } = methods

  const btnRef = useRef()

  const onClose = async (e: any) => {
    try {
      await e?.preventDefault()
      const id = e.target.href.split("#")[1]
      const section = document.getElementById(`${id}`)
      await section?.scrollIntoView({behavior: "smooth", block: "start"})
    } catch (e) {

    }
    onDiscClose()
  }

  useEffect(() => {
    return () => onDiscClose()
  }, [onDiscClose])

  return (
    <Drawer
      placement={"top"}
      onClose={onDiscClose}
      isOpen={isOpen}
      closeOnOverlayClick={true}
      isFullHeight={true}
      finalFocusRef={btnRef as any}
      returnFocusOnClose={false}
      size="full"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex height="80" alignItems="center" justifyContent="end" w="full">
            <CloseButton size="lg" onClick={onDiscClose} height="40" width="40" marginRight="20" />
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <VStack spacing="12" fontSize="32" fontWeight="500">
            {navLinks.map((navLink) => (<a href={navLink.to} onClick={onClose}>{navLink.title}</a>))}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )

}
