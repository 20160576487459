import { ChakraProvider } from "@chakra-ui/react"
import React from "react"
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom"
import { ContentContainer } from "./data/provider"
import { appRoutes } from "./routes"
import { theme } from "./theme"
import { Fonts } from "./theme/globals"

const CollectedAppProviders: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Fonts/>
      <ContentContainer.Provider>

        <Outlet />
      </ContentContainer.Provider>
    </ChakraProvider>
  )
}

const Application = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <CollectedAppProviders />,
      children: appRoutes
    },
  ])

  return (
    <RouterProvider router={router}/>
  )
}

export default Application
