import { extendTheme } from "@chakra-ui/react"

const rem = (px: number) => ({ [px]: `${px / 16}rem`})
const spread = (num: number) => ({[`${num}`]: `${num}`})

const spacing = {
  '0': '0',
  ...rem(1),
  ...rem(2),
  ...rem(4),
  ...rem(5),
  ...rem(6),
  ...rem(8),
  ...rem(10),
  ...rem(12),
  ...rem(14),
  ...rem(16),
  ...rem(18),
  ...rem(20),
  ...rem(22),
  ...rem(24),
  ...rem(28),
  ...rem(32),
  ...rem(36),
  ...rem(40),
  ...rem(48),
  ...rem(50),
  ...rem(52),
  ...rem(56),
  ...rem(60),
  ...rem(64),
  ...rem(72),
  ...rem(80),
  ...rem(96),
  ...rem(120),
  ...rem(160),
  ...rem(340),
  ...rem(380),
  ...rem(500),
  ...rem(560),
  ...rem(768),
  ...rem(1200),
}

export const theme = extendTheme({
  colors: {
    red: "#C03C27",
    orange: "#F98D3E",
    yellow: "#FFCF35",
    midnight: "#0B2C3A",
    darkblue: "#006585",
    cyan: "#009DAE",
    lightcyan: "#73C0D1",
    lightblue: "#93D1E3",
    lighterblue: "#D2F0F9",
    sky: "#EAF9FF"
  },
  fonts: {
    main: "Montserrat, sans-serif",
    alt: "Hind, sans-serif",
    heading: "Montserrat, sans-serif",
    body: "Hind, sans-serif",
  },
  fontSizes: {
    ...rem(10),
    ...rem(12),
    ...rem(14),
    ...rem(16),
    ...rem(18),
    ...rem(20),
    ...rem(22),
    ...rem(24),
    ...rem(26),
    ...rem(28),
    ...rem(30),
    ...rem(36),
  },
  fontWeights: {
    ...spread(100),
    ...spread(200),
    ...spread(300),
    ...spread(400),
    ...spread(500),
    ...spread(600),
    ...spread(700),
    ...spread(800),
    ...spread(900),
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  space: spacing,
  sizes: {
    ...spacing,
    max: "max-content",
    min: "min-content",
    full: "100%",
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    heading: "1.43",
    body: "1.25",
  },
  components: {
    Text: {
      baseStyle: {
        color: "#0B2C3A",
        fontSize: "16",
        fontWeight: "400",
      },
      variants: {
        secondary: {
          fontSize: "14",
        }
      }
    },
    Heading: {
      baseStyle: {
        color: "#0B2C3A",
        fontWeight: "700",
      },
      variants: {
        secondary: {
          color: "#2F3E44",
          fontWeight: "600"
        }
      }
    },
    Button: {
      baseStyle: {
        fontWeight: "600",
        color: "white",
        background: "orange",
        borderRadius: "200",
        paddingTop: "16",
        paddingBottom: "16",
        paddingLeft: "48",
        paddingRight: "48",
        justifyContent: "center",
        alignItems: "center",
      },
      variants: {
        secondary: {
          color: "orange",
          backgroundColor: "white"
        }
      },
      defaultProps: {
        colorScheme: "orange",
      }
    },
    Link: {
      baseStyle: {
        color: "#0B2C3A",
        textDecoration: "underline",
      },
      variants: {
        button: {
          color: "white",
          background: "orange",
          textDecoration: {hover: "none", base: "none"},
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "99999px",
          fontSize: "20",
          fontWeight: "600",
          font: "heading",
          gap: "4px",
        },
        buttonAlt: {
          color: "orange",
          background: "white",
          textDecoration: {hover: "none", base: "none"},
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "99999px",
          fontSize: "20",
          fontWeight: "600",
          font: "heading",
          gap: "4px",
        },
      },
      sizes: {
        buttonLg: {
          paddingTop: "12",
          paddingBottom: "12",
          paddingLeft: "32",
          paddingRight: "32",
          fontSize: "20",
        },
        buttonMd: {
          paddingTop: "10",
          paddingBottom: "10",
          paddingLeft: "26",
          paddingRight: "26",
          fontSize: "16",
        }
      }
    },
    Image: {
      defaultProps: {
        loading: "lazy"
      }
    }
  }
})
