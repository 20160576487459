import { Box, Flex, Heading, HStack, Link, Text, VStack, Image, Show } from "@chakra-ui/react"
import React from "react"
import { Container } from "../../components/Container"
import titleBarImage from 'assets/images/title-bar-image.png';

const DesktopFooterLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="557" height="667" viewBox="0 0 557 667" fill="none">
    <path d="M107.91 0H0V667H507C534.614 667 557 644.614 557 617V493.31C557 465.696 534.614 443.31 507 443.31H435.346C407.732 443.31 385.346 420.924 385.346 393.31V243.337C385.346 199.154 349.529 163.337 305.346 163.337H207.911C180.297 163.337 157.911 140.951 157.911 113.337V50C157.911 22.3858 135.525 0 107.91 0Z" fill="#006585"/>
  </svg>
)

const DesktopFooterRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="646" height="542" viewBox="0 0 646 542" fill="none">
    <path d="M392.029 0H646V462C646 506.183 610.183 542 566 542H80C35.8172 542 0 506.183 0 462V341C0 296.817 35.8172 261 80 261H121C165.183 261 201 225.183 201 181V165C201 126.064 232.564 94.5 271.5 94.5H297.529C322.09 94.5 342 74.5898 342 50.0294C342 22.3989 364.399 0 392.029 0Z" fill="#006585"/>
  </svg>
)

const DesktopFooterBottom = () => (
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" width="100%" height="516" viewBox="0 0 1440 516" fill="none" style={{ width: "100%", objectFit: "cover", position: "absolute", bottom: "0", left: "0"}}>
    <g filter="url(#filter0_d_13_59)">
      <path d="M30.6543 0H0V516H1440V94.5H1339.69C1295.51 94.5 1259.69 130.317 1259.69 174.5V193.068C1259.69 228.929 1230.62 258 1194.76 258H1174.07C1149.63 258 1129.82 277.811 1129.82 302.25C1129.82 326.689 1110.01 346.5 1085.57 346.5H284.787C240.604 346.5 204.787 310.683 204.787 266.5V178.941C204.787 147.77 179.517 122.5 148.345 122.5C117.174 122.5 91.9043 97.2304 91.9043 66.0588V61.25C91.9043 27.4226 64.4817 0 30.6543 0Z" fill="#0B2C3A"/>
    </g>
    <defs>
      <filter id="filter0_d_13_59" x="0" y="0" width="100%" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13_59"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13_59" result="shape"/>
      </filter>
    </defs>
  </svg>
)

const MobileFooterLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="199" height="231" viewBox="0 0 199 231" fill="none">
    <path d="M36.9985 0H0V231H179C190.046 231 199 222.046 199 211V150.5C199 139.454 190.046 130.5 179 130.5H168C156.954 130.5 148 121.546 148 110.5V80.5679C148 67.313 137.255 56.5679 124 56.5679H77C65.9543 56.5679 57 47.6136 57 36.5679V20C57 8.9543 48.0442 0 36.9985 0Z" fill="#006585"/>
  </svg>
)

const MobileFooterRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="145" height="262" viewBox="0 0 145 262" fill="none">
    <path d="M104.765 0H145V230C145 247.673 130.673 262 113 262H32C14.3269 262 0 247.673 0 230V160C0 151.163 7.16345 144 16 144H25.1161C36.1618 144 45.1161 135.046 45.1161 124V86.3243C45.1161 77.5848 52.2009 70.5 60.9404 70.5C69.6799 70.5 76.7647 63.4152 76.7647 54.6757V28C76.7647 12.536 89.3007 0 104.765 0Z" fill="#006585"/>
  </svg>
)

const MobileFooterBottom = () => (
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" width="100%" height="299" viewBox="0 0 390 299" fill="none">
    <g filter="url(#filter0_d_201_606)">
      <path d="M5.55432 0.0837479H0V299H390V18H366.599C352.552 18 341.165 29.3869 341.165 43.4333V54.9142C341.165 64.6265 333.292 72.5 323.58 72.5H319.064C311.845 72.5 305.994 78.3517 305.994 85.5701C305.994 92.7885 300.142 98.6402 292.924 98.6402H85.4048C68.8685 98.6402 55.4631 85.2348 55.4631 68.6984V54.0428C55.4631 45.6005 48.6192 38.7566 40.1769 38.7566C31.7346 38.7566 24.8907 31.9128 24.8907 23.4704V19.4202C24.8907 8.74096 16.2335 0.0837479 5.55432 0.0837479Z" fill="#0B2C3A"/>
    </g>
    <defs>
      <filter id="filter0_d_201_606" x="-1.6" y="0.0837402" width="393.2" height="302.116" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.6"/>
        <feGaussianBlur stdDeviation="0.8"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_201_606"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_201_606" result="shape"/>
      </filter>
    </defs>
  </svg>
)

const Social = ({ alt }: { alt?: boolean }): JSX.Element => {
  const fill = alt ? "#fff" : "#0B2C3A"
  return (
    <HStack spacing="32">
      <Link href="https://www.instagram.com/autogpt/" isExternal>
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
          <path d="M19.75 1H7.25C3.80369 1 1 3.80413 1 7.25V19.75C1 23.1959 3.80369 26 7.25 26H19.75C23.1963 26 26 23.1959 26 19.75V7.25C26 3.80413 23.1963 1 19.75 1ZM24.2143 19.75C24.2143 22.2115 22.2115 24.2143 19.75 24.2143H7.25C4.78854 24.2143 2.78571 22.2115 2.78571 19.75V7.25C2.78571 4.78854 4.78854 2.78571 7.25 2.78571H19.75C22.2115 2.78571 24.2143 4.78854 24.2143 7.25V19.75ZM13.5 7.25C10.0537 7.25 7.25 10.0541 7.25 13.5C7.25 16.9459 10.0537 19.75 13.5 19.75C16.9463 19.75 19.75 16.9459 19.75 13.5C19.75 10.0541 16.9463 7.25 13.5 7.25ZM13.5 17.9643C11.0385 17.9643 9.03571 15.9615 9.03571 13.5C9.03571 11.0385 11.0385 9.03571 13.5 9.03571C15.9615 9.03571 17.9643 11.0385 17.9643 13.5C17.9643 15.9615 15.9615 17.9643 13.5 17.9643ZM21.2768 5.72325C21.4372 5.8924 21.5357 6.12521 21.5357 6.35714C21.5357 6.58908 21.4372 6.82101 21.2768 6.99104C21.1072 7.15147 20.8748 7.25 20.6429 7.25C20.4105 7.25 20.1786 7.15147 20.009 6.99104C19.8481 6.82101 19.75 6.58908 19.75 6.35714C19.75 6.12521 19.8481 5.8924 20.009 5.72325C20.339 5.39279 20.9463 5.39279 21.2768 5.72325Z" fill={fill} stroke={fill}/>
        </svg>
      </Link>
      <Link href="https://www.youtube.com/@A_GPT" isExternal>
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="27" viewBox="0 0 23 27" fill="none">
          <path d="M3.97321 26C3.18467 26 2.42842 25.6868 1.87083 25.1292C1.31325 24.5716 1 23.8153 1 23.0268V4.01786C1 3.21747 1.31795 2.44987 1.88391 1.88391C2.44987 1.31795 3.21747 1 4.01786 1C4.52701 1.00389 5.02607 1.14252 5.46429 1.40179L20.7589 10.2589C21.2473 10.5391 21.6547 10.941 21.9415 11.4254C22.2283 11.9099 22.3847 12.4603 22.3955 13.0232C22.4063 13.5861 22.2711 14.1422 22.0031 14.6373C21.735 15.1323 21.3433 15.5495 20.8661 15.8482L5.55357 25.5446C5.07988 25.8416 4.53227 25.9993 3.97321 26ZM3.97321 2.78571C3.65423 2.79728 3.35218 2.93218 3.13068 3.16201C2.90917 3.39184 2.7855 3.69866 2.78571 4.01786V23.0268C2.78805 23.341 2.91391 23.6417 3.13611 23.8639C3.3583 24.0861 3.65899 24.2119 3.97321 24.2143C4.18597 24.2074 4.3928 24.1426 4.57143 24.0268L19.9554 14.3393C20.1707 14.2027 20.3472 14.0129 20.4677 13.7881C20.5883 13.5633 20.6488 13.3113 20.6434 13.0563C20.638 12.8014 20.5669 12.5521 20.437 12.3326C20.3071 12.1132 20.1227 11.9309 19.9018 11.8036L4.57143 2.94643C4.40367 2.84644 4.21308 2.79111 4.01786 2.78571H3.97321Z" fill={fill} stroke={fill}/>
        </svg>
      </Link>
      <Link href="https://twitter.com/autogpt" isExternal>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <g clip-path="url(#clip0_22_161)">
            <path d="M14.347 10.5804L23.3215 0H21.1948L13.4023 9.18678L7.17846 0H0L9.41167 13.892L0 24.9872H2.12677L10.3558 15.2856L16.9287 24.9872H24.1071L14.3465 10.5804H14.347ZM11.4341 14.0144L10.4805 12.6311L2.89308 1.62377H6.15968L12.2828 10.507L13.2364 11.8904L21.1958 23.4372H17.9292L11.4341 14.015V14.0144Z" fill={fill} stroke={fill}/>
          </g>
          <defs>
            <clipPath id="clip0_22_161">
              <rect width="24.1071" height="25" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </Link>
    </HStack>
  )
}

export const Footer = (): JSX.Element => {
  return (
    <Box position="relative" zIndex="0">
      <Flex position="absolute" top="0" left="0" w="full" zIndex="3" justifyContent="space-around">
        <VStack spacing="32" w="500" textAlign="center">
          <Heading fontSize="32">Contact Us</Heading>
          <Text>Questions about the event? Get in touch with us! You can email us at <Link href="mailto:hello@tapioca.is" isExternal>hello@tapioca.is</Link> and we'll get back to you as soon as possible.</Text>
          <Show above="md">
            <Social />
          </Show>
        </VStack>
      </Flex>
      <Box position="relative" paddingTop="340">
        <Flex w="full" justifyContent="space-between" position="relative" overflow="hidden">
          <Box>
            <Show above="md">
              <DesktopFooterLeft />
            </Show>
            <Show below="md" >
              <MobileFooterLeft />
            </Show>
          </Box>
          <Box bottom="0" right="0" position="absolute" overflow="hidden">
            <Show above="md">
              <DesktopFooterRight />
            </Show>
            <Show below="md">
              <MobileFooterRight />
            </Show>
          </Box>
        </Flex>
        <Box position="absolute" bottom="0" left="0" w="full" h="auto" zIndex="0">
          <Show above="md">
            <DesktopFooterBottom />
          </Show>
          <Show below="md">
            <MobileFooterBottom />
          </Show>
          <Box position="absolute" bottom="0" left="0" w="full" h="auto" pb="48" zIndex="1" color="white">
            <Container fontFamily="montserrat" flexDir={{md: "row", base: "column"}} justifyContent="space-between" alignItems="center">
              <HStack spacing="24" filter="brightness(0) invert(1)" align="left" marginBottom={{md: "unset", base: "24"}}>
                <Image src={titleBarImage} h="48" />
                <VStack spacing="8" fontSize="12" align="left">
                  <Flex gap="12" fontWeight="600" align="left">
                    <Link href="/docs/terms" isExternal>Terms and Conditions</Link>
                    <Link href="/docs/privacy" isExternal>Privacy Policy</Link>
                    <Link href="/docs/ethics" isExternal>Ethics Agreement</Link>
                  </Flex>
                  <Text fontSize="12">
                    Ⓒ {new Date().getFullYear()} Beat, Build, Break powered by AutoGPT
                  </Text>
                </VStack>
              </HStack>
              <Social alt />
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
